@import "variables";
@import "framework/framework";

html {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	background: -webkit-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
	background: -o-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
	background: -ms-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
	background: -moz-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
	background: linear-gradient(to right, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
	color: $color-white;
}

.bg-wrap {
	background: url('../img/bg-slice.png') repeat-y center top;
	background-size: 100% auto;
	padding-top: rem(50);
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	position: relative;

	> img {
		width: 100%;
	}

	> .container {
		left: 0;
		position: absolute;
		right: 0;

		&.top {
			align-items: center;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			top: rem(40);

			@include phone-down {
				background: $color-black;
				padding: 15px;
				position: static;
			}

			> h1 {
				flex: 0 0 auto;
				margin: 0;

				@include phone-sm-down {
					max-width: rem(200);
				}
			}

			> .socials {
				flex: 0 0 auto;
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(30);
					vertical-align: middle;

					+ li {
						margin-left: rem(15);
					}

					> a {
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}
					}
				}
			}
		}

		&.bottom {
			bottom: rem(40);
			text-align: center;

			@include tablet-down {
				background: $color-black;
				padding: rem(30) 15px;
				position: static;
			}

			> h2 {
				font-size: rem(24);
				font-weight: 300;
				letter-spacing: letter-spacing(200);
				margin: 0;
				text-indent: text-indent(200);
			}

			> .btns {
				margin-top: rem(30);

				> a {
					border-radius: rem(5);
					color: $color-black;
					display: inline-block;
					font-weight: 700;
					letter-spacing: letter-spacing(75);
					min-width: rem(303);
					opacity: 1;
					padding: rem(13);
					text-align: center;
					text-indent: text-indent(75);
					vertical-align: middle;

					@include phone-down {
						display: block;
					}

					@include hover-focus {
						opacity: 0.8;
					}

					&.btn-spotify {
						background: -webkit-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
						background: -o-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
						background: -ms-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
						background: -moz-linear-gradient(left, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);
						background: linear-gradient(to right, rgb(184, 93, 192) 0%, rgb(220, 132, 126) 100%);

						> i {
							color: $color-white;
						}
					}

					&.btn-apple {
						background: $color-white;
					}

					+ a {
						margin-left: rem(20);

						@include phone-down {
							margin-left: 0;
							margin-top: rem(20);
						}
					}
				}
			}
		}
	}
}

main {
	section {
		padding: rem(50) 0;

		.section-title {
			color: $color-black;
			font-size: rem(48);
			font-weight: 900;
			margin: 0 0 rem(50) 0;
			text-align: center;
		}

		&#newsletter {
			.thanks {
				font-weight: 900;
				text-align: center;
			}

			form {
				margin: 0 auto;
				max-width: rem(500);
				text-align: center;

				input {
					background: $color-white;
					border: 2px solid $color-black;
					border-radius: rem(5);
					color: $color-black;
					display: block;
					margin-bottom: rem(15);
					padding: rem(10);
					text-transform: uppercase;
					width: 100%;

					@include placeholder {
						color: $color-black;
					}

					&:focus {
						border-color: $color-white;
						outline: 0;
					}
				}

				button {
					background: $color-white;
					border: 0;
					border-radius: rem(5);
					color: $color-black;
					display: inline-block;
					font-weight: 700;
					letter-spacing: letter-spacing(75);
					opacity: 1;
					padding: rem(13);
					text-align: center;
					text-indent: text-indent(75);
					text-transform: uppercase;
					vertical-align: middle;

					@include hover-focus {
						opacity: 0.8;
					}
				}
			}
		}

		&#tour {
			#events {
				> div {
					align-items: center;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					font-size: rem(16);
					font-weight: 700;

					@include phone-down {
						display: block;
						line-height: line-height(24, 16);
						text-align: center;
					}

					&.no-events {
						flex: 0 0 auto;
						font-weight: 900;
						justify-content: center;
						text-align: center;
					}

					.event-date {
						flex: 0 0 12%;
					}

					.event-venue {
						flex: 0 0 32%;
						padding: 0 rem(20);

						@include phone-down {
							padding: 0;
						}
					}

					.event-location {
						flex: 0 0 32%;
					}

					.event-links {
						flex: 0 0 24%;
						padding-left: rem(20);
						text-align: right;

						@include phone-down {
							margin-top: rem(20);
							padding-left: 0;
							text-align: center;
						}

						.btn-rsvp {
							display: inline-block;
							font-weight: 900;
							vertical-align: middle;
						}

						.btn-tickets {
							background: $color-black;
							color: $color-white;
							display: inline-block;
							margin-left: rem(20);
							opacity: 1;
							padding: rem(8) rem(12);
							text-align: center;
							vertical-align: middle;
							@include hover-focus {
								opacity: 0.8;
							}
						}
					}

					+ div {
						margin-top: rem(25);
					}
				}
			}
		}

		&#merch {
			.section-content {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				font-weight: 900;
				justify-content: center;
				margin: 0 rem(-12.5);
				text-align: center;

				@include phone-down {
					display: block;
					margin: 0;
				}

				> div {
					flex: 0 0 33.333333%;
					padding: 0 rem(12.5);

					> a {
						display: inline-block;
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}
					}

					@include phone-down {
						padding: 0;

						+ div {
							margin-top: rem(30);
						}
					}
				}
			}
		}
	}
}

footer {
	padding: 0 0 rem(60) 0;

	.copyright {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;

		@include phone-down {
			display: block;
			text-align: center;
		}

		> a {
			display: inline-block;
			flex: 0 0 auto;
			opacity: 1;
			vertical-align: middle;

			@include hover-focus {
				opacity: 0.8;
			}
		}

		> div {
			flex: 0 1 auto;
			max-width: rem(850);
			padding-left: rem(25);

			@include phone-down {
				margin: rem(30) auto 0 auto;
				padding-left: 0;
			}

			p {
				font-size: rem(12);
				line-height: line-height(20, 12);
				margin: 0;

			}

			a {
				@include hover-focus {
					text-decoration: underline;
				}
			}
		}

	}
}